import profile from '../images/moi.JPG';

const About = () => {
    return (
        <>
            <h1 className='drop-shadow-md text-primary md:text-[70px]'>À Propos</h1>
            <h2 className='pt-2 text-primary'>Mais qui est Milan ?</h2>
            <div className='md:grid md:grid-cols-2 md:gap-4'>
                <div>
                    <p className='text-white pt-10'>Ayant complété une AEC en Conception de sites Web en 2020, je suis un passionné de programmation. Depuis l'obtention de mon diplôme, je ne cesse de faire des projets personnels et des projets pour des entreprises dans mes temps libres. Je suis quelqu'un de passionné, dynamique, débrouillard et qui adore relever plusieurs défis. </p>
                    <p className='text-white pt-4'>Compétences : HTML / CSS / Javascript / ReactJS / API / Wordpress / Photoshop / Illustrator</p>
                    <p className='text-white pt-4'>Connaissances : VueJS / PHP / MySQL / Github / Adobe Premiere Pro / Node-Red </p>
                </div>
                <div className='relative flex justify-content'>
                    <img className='w-4/6 p-1 bg-border mt-10' src={profile} alt="Profile pic" />
                </div>
            </div>
        </>
    )
}

export default About;