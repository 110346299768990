import courrier from '../images/icons8-courrier-94.png';
import linkedinIcon from '../images/icons8-linkedin-240.png';
import githubIcon from '../images/icons8-github-128.png';


const Contact = () => {
    return (
        <>
            <h1 className='drop-shadow-md text-primary md:text-[70px]'>Contact</h1>
            <h2 className='pt-2 text-primary'>Contactez-moi !</h2>
            <div className='md:grid md:grid-cols-2 md:gap-4'>
                <div>
                    <p className='text-white pt-10'>Vous avez une idée de projet, besoin d'un développeur front end ou des questions ? N'hésitez pas à me contacter en cliquant sur les liens ci-dessous et je vous recontacterai dans les plus brefs délais.</p>
                </div>
                <div className='relative flex justify-content flex-col'>
                    <div className='pl-[10px] pt-[50px] text-center'>
                        <p className='text-primary text-[20px] md:texxt-[40px]'>milanjeu@hotmail.com</p>
                    </div>
                    <div className='pl-[10px] pt-[10px] text-center'>
                        <a href='mailto:milanjeu@hotmail.com'><img className='inline ml-[10px] w-[40px] h-[40px] md:w-[60px] md:h-[60px]' src={courrier} alt="Courrier" /></a>
                        <a href='https://www.linkedin.com/in/milan-milanovic-9a4a44184/'><img className='inline ml-[10px] w-[40px] h-[40px] md:w-[60px] md:h-[60px]' target="_blank" src={linkedinIcon} alt="Linedkin" /></a>
                        <a href='https://github.com/milan-milanovic'><img className='inline ml-[10px] w-[40px] h-[40px] md:w-[60px] md:h-[60px]' target="_blank" src={githubIcon} alt="Github" /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;