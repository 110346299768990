import systemeIndice from '../images/work/systeme_indice.PNG';
import techfit from '../images/work/techfit.PNG';
import glassmoSite from '../images/work/glassmo_site.PNG';
import kinos from '../images/work/kinos.png';
import todoListApp from '../images/work/todo_list.PNG';
import apiWeather from '../images/work/api_weather.PNG';

const images = [
    { image: systemeIndice, title: "Système d'indice pour un jeu d'évasion", desc: 'HTML / CSS / Javascript'},
    { image: techfit, title: <a href='https://liguefutsaltechfit.ca/' target="_blank">futsaltechfit.ca</a>, desc: 'Wordpress'},
    { image: glassmoSite, title: 'Template pour portfolio', desc: 'HTML / CSS'},
    { image: kinos, title: 'Section de commande pour Kinos', desc: 'HTML / CSS / PHP / MySQL'},
    { image: todoListApp, title: 'Application Todo List', desc: 'HTML / CSS / ReactJS'},
    { image: apiWeather, title: 'API de météo', desc: 'HTML / CSS / ReactJS / API'},
]

const Work = () => {
    return (
        <>
            <h1 className='drop-shadow-md text-primary md:text-[70px]'>Mes projets</h1>
            <h2 className='pt-2 text-primary'>Voici différents projets sur lesquels j'ai travaillé</h2>
            <div>
                <div>
                    <p className='text-white pt-10'>J'ai travaillé sur plusieurs projets différents. Voici seulement quelques uns d'entre eux.</p>
                </div>
            </div>
            <div className='md:grid md:grid-cols-2 md:gap-4 mt-4'>
                {images.map((item, key) => 
                    <div className='mb-4 md:mb-0 rounded-[20px] border-2 border-cover relative overflow-hidden ' key={key}>
                        <img src={item.image} alt="" />
                        <div className='absolute bottom-0 z-10 w-full h-[80px]'>
                            <div className='absolute bg-black opacity-80 w-full h-full'></div>
                            <h2 className='top-4 left-4 relative font-bold'>{item.title}</h2>
                            <p className='top-4 left-4 relative text-white'>{item.desc}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Work;